// PhilosopherInfo.js
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChosenPhilosophersContext } from '../context/ChosenPhilosophersContext';
import Purple_chat_bubble from '../images/icons/Purple_chat_bubble.svg'
import Add_to_group from '../images/icons/Add_to_group.svg'
import Remove_from_group from '../images/icons/Remove_fron_group.svg'
import ConfirmationModal from './ConfirmationModal';

const PhilosopherCards = ({ philosopher, setIsQuestionsOpen, setIsPhilosopherCatalogVisible, setCurrentUserQuestion, setSpeakerSequence, setIsPhilosophersResponding, setCurrentSpeakerIndex, conversationHistory}) => {
  const { selectedPhilosophers, setSelectedPhilosophers, togglePhilosopher, selectedCount } = useContext(ChosenPhilosophersContext);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const navigate = useNavigate();
  const prompt = "Please, give the visitor of PhiloSphere a personal greeting fitting for your character. Then introduce yourself and continue to introduce the idea of your philosopy that you find the most important. Then proceed to ask them what kind of wisdom or advice they are seeking. Your answer should be in English"

  if (!philosopher) {
    console.log("The philosopher data is undefined")
    return null; //returns noll if philosopher data is undefined
  }

  const {backgroundColor, sp_image, name, epithet, lifeSpan, descriptiveWords} = philosopher;
  const isInGroup = selectedPhilosophers.includes(philosopher)

  const handleConverseClick = (event) => {
    event.stopPropagation();
    if (selectedCount > 0) {
      setIsConfirmationModalOpen(true);
    } else {
      setIsPhilosophersResponding(true);
      setCurrentSpeakerIndex(0)
      setSelectedPhilosophers([philosopher])
      setIsPhilosopherCatalogVisible(false)
      setCurrentUserQuestion(prompt);

      // Ensure selectedPhilosophers is updated before setting the sequence
      setTimeout(() => {
        const updatedSelectedPhilosophers = [philosopher];
        setSpeakerSequence(updatedSelectedPhilosophers.map((_, index) => index + 1));
      }, 100);
      setIsQuestionsOpen(true)
    }
  };

  const handleGroupToggleClick = (event) => {
    event.stopPropagation();
    togglePhilosopher(philosopher)
  }

  const handleConfirm = () => {
    setIsConfirmationModalOpen(false)
    setIsPhilosophersResponding(true);
    setCurrentSpeakerIndex(0)
    setSelectedPhilosophers([philosopher])
    setIsPhilosopherCatalogVisible(false)
    setCurrentUserQuestion(prompt);

    // Ensure selectedPhilosophers is updated before setting the sequence
    setTimeout(() => {
      const updatedSelectedPhilosophers = [philosopher];
      setSpeakerSequence(updatedSelectedPhilosophers.map((_, index) => index + 1));
    }, 100);
    setIsQuestionsOpen(true);
  }

  const handleCancel = () => {
    setIsConfirmationModalOpen(false)
  }

  const handleCardClick = (event) => {
    if (event.target.tagName !== 'BUTTON'){
      navigate(`/${philosopher.name.toLowerCase().replace(/\s+/g, '-')}`);
    }
  }


  
  return (
    <div className='Philosopher-card' onClick={handleCardClick}>
      <div className='Image-section'>
        <img src={backgroundColor} alt='Visual backdrop for the philosopher' className='Backdrop' />
        <img src={sp_image} alt={name} className='Card-image'/>
      </div>
      <div className='Card-information-container'>
        <h3>{name}</h3>
        <h4>{epithet}</h4>
        <h5>{lifeSpan}</h5>
        <div>
          <p>{descriptiveWords[0]}</p>
          <span className='Dot-spacer' ></span>
          <p>{descriptiveWords[1]}</p>
          <span className='Dot-spacer' ></span>
          <p>{descriptiveWords[2]}</p>
        </div>
      </div>
      <div className='Card-buttons'>
        <button className='Primary-outlined-button' onClick={handleConverseClick}> <img src={Purple_chat_bubble} />Converse</button>
        {isInGroup ?
         <button className='Secondary-outlined-button' id='Remove-philosopher' onClick={handleGroupToggleClick}> <img src={Remove_from_group}/> Remove </button>
         :
         <button className='Secondary-outlined-button' onClick={handleGroupToggleClick}> <img src={Add_to_group}/> Group </button>
        }
      </div>
      <ConfirmationModal 
        isOpen={isConfirmationModalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        philosopherName={name}
      />
    </div>
  );
};

export default PhilosopherCards;