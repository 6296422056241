// PhilosopherInfo.js
import React from 'react';

const ConfirmationModal = ({ isOpen, onConfirm, onCancel }) => {
  if (!isOpen) return null; 

  return ( 
    <div className='Modal-overlay'id='Modal-overlay'> 
      <div className='Modal-content'>
        <h2 className='Trash-modal-header'>Are you sure?</h2>
        <p className='Decision-description'>Do you want to remove all the philosophers in your chat group?</p>
        <div>
          <button onClick={onConfirm}>Yes, clear group</button>
          <button onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  )
};

export default ConfirmationModal;


