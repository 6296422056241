// PhilosopherCatalog.js

import React from 'react';
import '../App.css';
import DividerLine from './DividerLine';
import AffinityGroups from './AffinityGroups';
import PhilosopherThemeCarousel from './pre_selected_theme_groups/PhilosopherThemeCarousel.js';
import { affinities } from '../PhilosophersData';

const PhilosopherCatalog = ({isVisible, setIsQuestionsOpen, setIsPhilosopherCatalogVisible, setCurrentUserQuestion, setSpeakerSequence, setIsPhilosophersResponding, setCurrentSpeakerIndex={setCurrentSpeakerIndex}, conversationHistory}) => {
  return (
    <div className={`Philosopher-catalog ${isVisible ? '' : 'fade-out'}`}>
      <PhilosopherThemeCarousel 
        setIsQuestionsOpen={setIsQuestionsOpen}
        setIsPhilosopherCatalogVisible={setIsPhilosopherCatalogVisible} 
        setCurrentUserQuestion={setCurrentUserQuestion} 
        setSpeakerSequence={setSpeakerSequence} 
        setIsPhilosophersResponding={setIsPhilosophersResponding}
        setCurrentSpeakerIndex={setCurrentSpeakerIndex}
        conversationHistory={conversationHistory}
      />
      <DividerLine/>
      {affinities.map(group => (
        <AffinityGroups 
          setIsQuestionsOpen={setIsQuestionsOpen}
          key={group.name} 
          group={group} 
          setIsPhilosopherCatalogVisible={setIsPhilosopherCatalogVisible}
          setCurrentUserQuestion={setCurrentUserQuestion} 
          setSpeakerSequence={setSpeakerSequence} 
          setIsPhilosophersResponding={setIsPhilosophersResponding}
          setCurrentSpeakerIndex={setCurrentSpeakerIndex}
          conversationHistory={conversationHistory}
        />
      ))}
    </div>
  );
};

export default PhilosopherCatalog;
