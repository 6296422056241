import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChosenPhilosophersContext } from '../context/ChosenPhilosophersContext';
import Grey_X from '../images/icons/Darkgrey_X.svg';
import Remove_fron_group from '../images/icons/Remove_fron_group.svg'
import Red_trashcan from '../images/icons/Red_trashcan.svg'
import White_chat_bubble from '../images/icons/White_chat_bubble.svg';
import TrashModal from './TrashModal'

const ChatGroupModal = ({ isOpen, onClose, startConversation}) => {
    const { selectedPhilosophers, setSelectedPhilosophers, togglePhilosopher, selectedCount } = useContext(ChosenPhilosophersContext);
    const [isTrashModalOpen, setIsTrashModalOpen] = useState(false);
    const navigate = useNavigate();

    if (!isOpen) return null;

    const handleTrashcanClick = () => {
        setIsTrashModalOpen(true);
    }
    
    const handleConfirm = () => {
        setSelectedPhilosophers([])
        setIsTrashModalOpen(false)
    }

    const handleCancel = () => {
        setIsTrashModalOpen(false)
    }

    const handlePhilosopherClick = ( philosopherName ) => {
        navigate(`/${philosopherName.toLowerCase().replace(/\s+/g, '-')}`);
    }    

    const handleDivClick = (event, philosopherName) => {
        // Check if the click event originated from the remove button
        if (event.target.closest('.X-button')) {
            return;
        }
        handlePhilosopherClick(philosopherName);
    }



    return (
        <div>
            <div className="Modal-container">
                <div className='Modal-top-bar'>
                    <button className='X-button' onClick={onClose}> <img src={Grey_X} alt='Close'/> </button>
                </div>
                <div className='Header-divider'>
                    <h2>Selected group members</h2>
                    <button onClick={handleTrashcanClick}> <img src={Red_trashcan}/> </button>
                </div>
                <div className='Group-members'>
                    {selectedPhilosophers.map((philosopher, index) => (
                        <div className='Selected-philosopher' onClick={(event) => handleDivClick(event, philosopher.name)}>
                            <div className='Content'>
                                <img className='Philosopher-image' key={index} src={philosopher.sp_image} alt="Image of the philosopher"/>
                                <div className='Philosopher-name-n-epithet'>
                                    <h3 key={index}>{philosopher.name}</h3>
                                    <h4 key={index}>{philosopher.epithet}</h4>
                                </div>
                                <button className='X-button'> <img src={Remove_fron_group} onClick={() => togglePhilosopher(philosopher)}/> </button>
                            </div>
                        </div>
                    ))}
                    {selectedCount === 0 && (
                    <p> Your chat group is currently empty. <br/> Add philosophers to dive into discussions of multiple perspectives.</p>

                    )}
                </div>
                <div className='Modal-bottom-bar'> 
                    <div className='White-line'></div>
                    <button className='Start-conversation-button' onClick={startConversation}> <img src={White_chat_bubble}/> Converse </button> 
                </div>
            </div>
            <TrashModal 
                isOpen={isTrashModalOpen}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
        </div>
    );
};

export default ChatGroupModal;
