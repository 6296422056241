// AffinityGroups.js
import React, {useRef, useState, useEffect} from 'react';
import PhilosopherCards from './PhilosopherCards';
import { philosophers } from '../PhilosophersData';

const AffinityGroups = ({ group, setIsQuestionsOpen, setIsPhilosopherCatalogVisible, setCurrentUserQuestion, setSpeakerSequence, setIsPhilosophersResponding, setCurrentSpeakerIndex, conversationHistory }) => {
  const philosopherList = group.philosophers
    .map(id => philosophers.find(p => p.id === id))
    .filter(philosopher => philosopher)

  const listRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const scrollLeft = () => {
    if (listRef.current) {
      listRef.current.scrollBy({ left: -window.innerWidth+320, behavior: 'smooth' });
    }
  };
  const scrollRight = () => {
    if (listRef.current) {
      listRef.current.scrollBy({ left: window.innerWidth-320, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      if (listRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(scrollLeft + clientWidth < scrollWidth)
      }
    };

    const list = listRef.current;
    if (list) {
      list.addEventListener('scroll', handleScroll);
      handleScroll();
    }

    return () => {
      if (list) {
        list.removeEventListener('scroll', handleScroll);
      }
    }
  }, []);


  return (
    <div 
      className='Affinity-group'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <h2>{group.name}</h2>
      <div
        className='Horisontal-list-of-philosophers' ref={listRef} >
        {philosopherList.map(philosopher => (
          <PhilosopherCards 
            key={philosopher.id} 
            philosopher={philosopher} 
            setIsQuestionsOpen={setIsQuestionsOpen}
            setIsPhilosopherCatalogVisible={setIsPhilosopherCatalogVisible}
            setCurrentUserQuestion={setCurrentUserQuestion} 
            setSpeakerSequence={setSpeakerSequence} 
            setIsPhilosophersResponding={setIsPhilosophersResponding}
            setCurrentSpeakerIndex={setCurrentSpeakerIndex}
            conversationHistory={conversationHistory}
          />
        ))}
      </div>
      {isHovered && canScrollLeft && (
        <div className='Blur-for-horisontal-scroll' id='Left-blur'> 
          <button className='Scroll-button-left' onClick={scrollLeft}> 
            <svg className='icon' xmlns="http://www.w3.org/2000/svg" width="15" height="26" fill="none">
              <path stroke-linecap="round" stroke-width="2" d="m13.6 1-12 10.5a2 2 0 0 0 0 3l12 10.5"/>
            </svg>
          </button>
        </div>
      )}
      {isHovered && canScrollRight && (
        <div className='Blur-for-horisontal-scroll' id='Right-blur'> 
          <button className='Scroll-button-right' onClick={scrollRight}> 
            <svg className='icon' xmlns="http://www.w3.org/2000/svg" width="15" height="26" fill="none">
              <path stroke-linecap="round" stroke-width="2" d="m1.2 1 11.9 10.5a2 2 0 0 1 0 3L1.2 25"/>
            </svg>
          </button> 
        </div>
      )}
    </div>
  );
};

export default AffinityGroups;