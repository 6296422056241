//This is the function that adds more diffused lights to the background when more messages are added.
export function addLights() {
  const lightsContainer = document.querySelector('.Diffused-lights-container')
  const lightsPerSet = 9;

  for (let i = 1; i <= lightsPerSet; i++) {
    const newLight = document.createElement('div');
    // Assign class based on the set number and the light index
    newLight.classList.add(`Light-${i}`);
    lightsContainer.appendChild(newLight);
  }
}