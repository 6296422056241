import React, { createContext, useState, useEffect } from 'react';

//Create the context
export const ChosenPhilosophersContext = createContext();

//Create a provider component
export const ChosenPhilosophersProvider = ({ children }) => {
    const [selectedPhilosophers, setSelectedPhilosophers] = useState([]);
    const [selectedCount, setSelectedCount] = useState(0);
    const [counterOfTimesPhilosophersHaveBeenChanged, setUpCounterOfTimesPhilosophersHaveBeenChanged] = useState(0);

    //Add or remove a philosopher from the list
    const togglePhilosopher = (philosopher) => {
        setSelectedPhilosophers((prev) => {
            if (prev.includes(philosopher)) {
                return prev.filter(p => p !== philosopher );
            } else {
                return [...prev, philosopher];
            }
        });
    };

    useEffect(() => {
        setSelectedCount(selectedPhilosophers.length);
        setUpCounterOfTimesPhilosophersHaveBeenChanged(counterOfTimesPhilosophersHaveBeenChanged + 1)
      }, [selectedPhilosophers]);

    return (
        <ChosenPhilosophersContext.Provider value={{ selectedPhilosophers, setSelectedPhilosophers, togglePhilosopher, selectedCount, counterOfTimesPhilosophersHaveBeenChanged}}>
            {children}
        </ChosenPhilosophersContext.Provider>
    );
};