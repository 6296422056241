// This will be one of the themed pre-selected groups within the carousel above the fold as the user enters the sphere
import React, { useContext, useState, useMemo, useRef, useEffect } from 'react';
import { philosophers } from '../../PhilosophersData';
import { ChosenPhilosophersContext } from '../../context/ChosenPhilosophersContext';
import ConfirmationModal from '../ConfirmationModal';
import MemoizedPhilosopherImage from './MemoizedPhilosopherImages';


//Import pictures
import FriedrichNietzscheWhiteBG from '../../images/marbleStatuePictures/FrontFacing/FriedrichNietzscheWhiteBG.jpg';
import EpictetusWhiteBG from '../../images/marbleStatuePictures/FrontFacing/EpictetusWhiteBG.jpg';
import ArthurSchopenhauerWhiteBG from '../../images/marbleStatuePictures/FrontFacing/ArthurSchopenhauerWhiteBG.jpg';
import HannahArendtWhiteBG from '../../images/marbleStatuePictures/FrontFacing/HannahArendtWhiteBG.jpg';
import White_chat_bubble from '../../images/icons/White_chat_bubble.svg';

// Import videos
import FriedrichNietzscheVideo from '../../videoes/FriedrichNietzsche.mp4';
import EpictetusVideo from '../../videoes/Epictetus.mp4';
import ArthurSchopenhauerVideo from '../../videoes/ArthurSchopenhauer.mp4';
import HannahArendtVideo from '../../videoes/HannahArendt.mp4';

const NavigatingLifesHardships = ({ setIsQuestionsOpen, setIsPhilosopherCatalogVisible, setCurrentUserQuestion, setSpeakerSequence, setIsPhilosophersResponding, setCurrentSpeakerIndex, currentThemeIndex, setAutoPlayOnPause }) => {
  const { setSelectedPhilosophers, selectedCount} = useContext(ChosenPhilosophersContext);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  // States for playing the videoes by change of CSS style from image to video in the case that the videos have been fully loaded and is ready to play seemlessly
  const [isFriedrichNietzscheVideoReady, setFriedrichNietzscheVideoReady] = useState(false);
  const [isEpictetusVideoReady, setEpictetusVideoReady] = useState(false);
  const [isArthurSchopenhauerVideoReady, setArthusSchopenhauerVideoReady] = useState(false);
  const [isHannahArendtVideoReady, setHannahArendtVideoReady] = useState(false);
  const [isPageVisible, setPageVisible] = useState(true);

  // Refs to access videos elements as means to play them from the start when a user go's to a new theme in the theme carousel
  const nietzscheVideoRef = useRef(null);
  const epictetusVideoRef = useRef(null);
  const schopenhauerVideoRef = useRef(null);
  const arendtVideoRef = useRef(null);

  // Variable for the modal to show if one wants to chat with these instead of what was allready in the basket
  const name = 'Friedrich Nietzsche, Epictetus, Arthur Schopenhauer and Hannah Arendt'

  // Variable that initiates the philosophers on the topic of the conversation
  const prompt = "Please, give the visitor of PhiloSphere a personal heartfelt greeting as the current topic can be difficult to discuss. The greeting should be fitting for your caracter. Then introduce the theme of navigating life's hardships and tell a story about how you have experienced pain yourself in your life as well as what ideas within the topic your work revolved. Then end by asking what questions related to the topic they are pondering or what advice they are seeking. Answer in English until you've been told otherwise"

  const themedGroup = useMemo(() => [
    philosophers.find(p => p.name === 'Epictetus'),
    philosophers.find(p => p.name === 'Hannah Arendt'),
    philosophers.find(p => p.name === 'Arthur Schopenhauer'),
    philosophers.find(p => p.name === 'Friedrich Nietzsche'),
  ],[])

  const handleStartConverseClick = () => {
    if (selectedCount > 0) {
      setIsConfirmationModalOpen(true);
      setAutoPlayOnPause(true)
    } else {
        setIsPhilosophersResponding(true);
        setCurrentSpeakerIndex(0);
        setSelectedPhilosophers(themedGroup)
        setIsPhilosopherCatalogVisible(false)
        setCurrentUserQuestion(prompt);

        // Ensure selectedPhilosophers is updated before setting the sequence
        setTimeout(() => {
          const updatedSelectedPhilosophers = themedGroup;
          setSpeakerSequence(updatedSelectedPhilosophers.map((_, index) => index + 1));
        }, 100);
        setIsQuestionsOpen(true)
    }
  }

  const handleConfirm = () => {
    setIsPhilosophersResponding(true);
    setCurrentSpeakerIndex(0);
    setSelectedPhilosophers(themedGroup)
    setIsPhilosopherCatalogVisible(false)
    setCurrentUserQuestion(prompt);

    // Ensure selectedPhilosophers is updated before setting the sequence
    setTimeout(() => {
      const updatedSelectedPhilosophers = themedGroup;
      setSpeakerSequence(updatedSelectedPhilosophers.map((_, index) => index + 1));
    }, 100);
    setAutoPlayOnPause(false)
    setIsQuestionsOpen(true)
  }

  const handleCancel = () => {
    setIsConfirmationModalOpen(false)
    setAutoPlayOnPause(false)
  }

  // Function to pause videos when page is not visible
  const pauseVideos = () => {
    if (nietzscheVideoRef.current) nietzscheVideoRef.current.pause();
    if (epictetusVideoRef.current) epictetusVideoRef.current.pause();
    if (schopenhauerVideoRef.current) schopenhauerVideoRef.current.pause();
    if (arendtVideoRef.current) arendtVideoRef.current.pause();
  };

  // Function to play videos from the start when they are ready
  const playVideosFromStart = () => {
    if (isFriedrichNietzscheVideoReady && nietzscheVideoRef.current) {
      nietzscheVideoRef.current.currentTime = 0;
      nietzscheVideoRef.current.play().catch(error => console.log('Play request was interrupted:', error));
    }
    if (isEpictetusVideoReady && epictetusVideoRef.current) {
      epictetusVideoRef.current.currentTime = 0;
      epictetusVideoRef.current.play().catch(error => console.log('Play request was interrupted:', error));
    }
    if (isArthurSchopenhauerVideoReady && schopenhauerVideoRef.current) {
      schopenhauerVideoRef.current.currentTime = 0;
      schopenhauerVideoRef.current.play().catch(error => console.log('Play request was interrupted:', error));
    }
    if (isHannahArendtVideoReady && arendtVideoRef.current) {
      arendtVideoRef.current.currentTime = 0;
      arendtVideoRef.current.play().catch(error => console.log('Play request was interrupted:', error));
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setPageVisible(false);
        pauseVideos();
      } else {
        setPageVisible(true);
        playVideosFromStart();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isFriedrichNietzscheVideoReady, isEpictetusVideoReady, isArthurSchopenhauerVideoReady, isHannahArendtVideoReady]);

  useEffect(() => {
    if (currentThemeIndex === 3 && isPageVisible) {
      playVideosFromStart();
    }
  }, [currentThemeIndex, isPageVisible, isFriedrichNietzscheVideoReady, isEpictetusVideoReady, isArthurSchopenhauerVideoReady, isHannahArendtVideoReady]);

  return (
    <div className='Carousel-theme'>
      <h2>Navigating Lifes Hardships</h2>
      <div className='Philosophers-in-theme'>
        <div className='Group1'>
          <div className="Philosopher">
            <MemoizedPhilosopherImage src={FriedrichNietzscheWhiteBG} alt="Friedrich Nietzsche" style={{ display: isFriedrichNietzscheVideoReady ? 'none' : 'block' }}/>
            <video
              ref={nietzscheVideoRef}
              src={FriedrichNietzscheVideo}
              muted
              playsInline
              onCanPlayThrough={() => {
                setFriedrichNietzscheVideoReady(true);
              }}
              style={{ display: isFriedrichNietzscheVideoReady ? 'block' : 'none'}}
              className='Philosopher-intro-video'
            />
            <h3>Friedrich Nietzsche</h3>
          </div>
          <div className="Philosopher">
            <MemoizedPhilosopherImage src={EpictetusWhiteBG} alt="Epictetus" style={{ display: isEpictetusVideoReady ? 'none' : 'block' }}/>
            <video
              ref={epictetusVideoRef}
              src={EpictetusVideo}
              muted
              playsInline
              onCanPlayThrough={() => {
                setEpictetusVideoReady(true);
              }}
              style={{ display: isEpictetusVideoReady ? 'block' : 'none'}}
              className='Philosopher-intro-video'
            />
            <h3>Epictetus</h3>
          </div>
        </div>
        <div className='Group2'>
          <div className="Philosopher">
            <MemoizedPhilosopherImage src={ArthurSchopenhauerWhiteBG} alt="Arthur Schopenhaur" style={{ display: isArthurSchopenhauerVideoReady ? 'none' : 'block' }}/>
            <video
              ref={schopenhauerVideoRef}
              src={ArthurSchopenhauerVideo}
              muted
              playsInline
              onCanPlayThrough={() => {
                setArthusSchopenhauerVideoReady(true);
              }}
              style={{ display: isArthurSchopenhauerVideoReady ? 'block' : 'none'}}
              className='Philosopher-intro-video'
            />
            <h3>Arthur Schopenhauer</h3>
          </div>
          <div className="Philosopher">
            <MemoizedPhilosopherImage src={HannahArendtWhiteBG} alt="Hannah Arendt" style={{ display: isHannahArendtVideoReady ? 'none' : 'block' }}/>
            <video
              ref={arendtVideoRef}
              src={HannahArendtVideo}
              muted
              playsInline
              onCanPlayThrough={() => {
                setHannahArendtVideoReady(true);
              }}
              style={{ display: isHannahArendtVideoReady ? 'block' : 'none'}}
              className='Philosopher-intro-video'
            />
            <h3>Hannah Arendt</h3>
          </div>
        </div>
      </div>
      <p className='Theme-description' id='Theme-description' >Gain invaluable perspectives on dealing with pain, loss, and struggle. Engage with ideas that empower you to navigate life's inevitable challenges</p>
      <button className='Start-conversation-button' onClick={handleStartConverseClick}> <img src={White_chat_bubble} /> Start Conversation </button>
      <ConfirmationModal 
        isOpen={isConfirmationModalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        philosopherName={name}
      />
    </div>
  );
};

export default NavigatingLifesHardships;