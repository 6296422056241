// This will be one of the themed pre-selected groups within the carousel above the fold as the user enters the sphere
import React, { useContext, useState, useMemo, useRef, useEffect } from 'react';
import { philosophers } from '../../PhilosophersData';
import { ChosenPhilosophersContext } from '../../context/ChosenPhilosophersContext';
import ConfirmationModal from '../ConfirmationModal';
import MemoizedPhilosopherImage from './MemoizedPhilosopherImages';


// Import pictures
import Plato from '../../images/marbleStatuePictures/FrontFacing/PlatoWhiteBG.jpg';
import SimoneDeBeauvoir from '../../images/marbleStatuePictures/FrontFacing/SimoneDeBeauvoirWhiteBG.jpg';
import IrisMurdoch from '../../images/marbleStatuePictures/FrontFacing/IrisMurdochWhiteBG.jpg';
import ErichFromm from '../../images/marbleStatuePictures/FrontFacing/ErichFrommWhiteBG.jpg';
import White_chat_bubble from '../../images/icons/White_chat_bubble.svg';

// Import videos
import PlatoVideo from '../../videoes/Plato.mp4';
import SimoneDeBeauvoirVideo from '../../videoes/SimoneDeBeauvoir.mp4';
import IrisMurdochVideo from '../../videoes/IrisMurdoch.mp4';
import ErichFrommVideo from '../../videoes/ErichFromm.mp4';

const LivingInLoveAndRelationships = ({ setIsQuestionsOpen, setIsPhilosopherCatalogVisible, setCurrentUserQuestion, setSpeakerSequence, setIsPhilosophersResponding, setCurrentSpeakerIndex, currentThemeIndex, setAutoPlayOnPause }) => {
  const { selectedPhilosophers, setSelectedPhilosophers, selectedCount} = useContext(ChosenPhilosophersContext);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  
  // States for playing the videoes by change of CSS style from image to video in the case that the videos have been fully loaded and is ready to play seemlessly
  const [isPlatoVideoReady, setPlatoVideoReady] = useState(false);
  const [isSimoneDeBeauvoirVideoReady, setSimoneDeBeauvoirVideoReady] = useState(false);
  const [isIrisMurdochVideoReady, setIrisMurdochVideoReady] = useState(false);
  const [isErichFrommVideoReady, setErichFrommVideoReady] = useState(false);
  const [isPageVisible, setPageVisible] = useState(true);

  // Refs to access videos elements as means to play them from the start when a user go's to a new theme in the theme carousel
  const platoVideoRef = useRef(null);
  const simoneVideoRef = useRef(null);
  const irisVideoRef = useRef(null);
  const erichVideoRef = useRef(null);


  // Variable for the modal to show if one wants to chat with these instead of what was allready in the basket
  const name = 'Plato, Simone de Beauvoir, Iris Murdoch and Erich Fromm' 

  // Variable that initiates the philosophers on the topic of the conversation
  const prompt = "Please, give the visitor of PhiloSphere a personal greeting fitting for your character. Then introduce the theme of Love and Relationships with a story from your life of how you experienced it and worked with love as a topic. Then end by asking what questions related to the topic they are pondering or what advice they are seeking. Answer in English until you've been told otherwise"
  

  const themedGroup = useMemo(() => [
    philosophers.find(p => p.name === 'Plato'),
    philosophers.find(p => p.name === 'Simone de Beauvoir'),
    philosophers.find(p => p.name === 'Iris Murdoch'),
    philosophers.find(p => p.name === 'Erich Fromm')
  ],[])

  const handleStartConverseClick = () => {
    if (selectedCount > 0) {
      setIsConfirmationModalOpen(true);
      setAutoPlayOnPause(true)
    } else {
      setIsPhilosophersResponding(true);
      setCurrentSpeakerIndex(0);
        setSelectedPhilosophers(themedGroup)
        setIsPhilosopherCatalogVisible(false)
        setCurrentUserQuestion(prompt);

        // Ensure selectedPhilosophers is updated before setting the sequence
        setTimeout(() => {
          const updatedSelectedPhilosophers = themedGroup;
          setSpeakerSequence(updatedSelectedPhilosophers.map((_, index) => index + 1));
        }, 100);
      setIsQuestionsOpen(true);
    }
  };

  const handleConfirm = () => {
    setIsPhilosophersResponding(true);
    setCurrentSpeakerIndex(0);
    setSelectedPhilosophers(themedGroup)
    setIsPhilosopherCatalogVisible(false)
    setCurrentUserQuestion(prompt);

    // Ensure selectedPhilosophers is updated before setting the sequence
    setTimeout(() => {
      const updatedSelectedPhilosophers = themedGroup;
      setSpeakerSequence(updatedSelectedPhilosophers.map((_, index) => index + 1));
    }, 100);
    setAutoPlayOnPause(false)
    setIsQuestionsOpen(true);
  }


  const handleCancel = () => {
    setIsConfirmationModalOpen(false)
    setAutoPlayOnPause(false)
  }

  // Function to pause videos when page is not visible
  const pauseVideos = () => {
    if (platoVideoRef.current) platoVideoRef.current.pause();
    if (simoneVideoRef.current) simoneVideoRef.current.pause();
    if (irisVideoRef.current) irisVideoRef.current.pause();
    if (erichVideoRef.current) erichVideoRef.current.pause();
  };

  // Function to play videos from the start when they are ready
  const playVideosFromStart = () => {
    if (isPlatoVideoReady && platoVideoRef.current) {
      platoVideoRef.current.currentTime = 0;
      platoVideoRef.current.play().catch(error => console.log('Play request was interrupted:', error));
    }
    if (isSimoneDeBeauvoirVideoReady && simoneVideoRef.current) {
      simoneVideoRef.current.currentTime = 0;
      simoneVideoRef.current.play().catch(error => console.log('Play request was interrupted:', error));
    }
    if (isIrisMurdochVideoReady && irisVideoRef.current) {
      irisVideoRef.current.currentTime = 0;
      irisVideoRef.current.play().catch(error => console.log('Play request was interrupted:', error));
    }
    if (isErichFrommVideoReady && erichVideoRef.current) {
      erichVideoRef.current.currentTime = 0;
      erichVideoRef.current.play().catch(error => console.log('Play request was interrupted:', error));
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setPageVisible(false);
        pauseVideos();
      } else {
        setPageVisible(true);
        playVideosFromStart();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isPlatoVideoReady, isSimoneDeBeauvoirVideoReady, isIrisMurdochVideoReady, isErichFrommVideoReady]);

  useEffect(() => {
    if (currentThemeIndex === 0 && isPageVisible) {
      playVideosFromStart();
    }
  }, [currentThemeIndex, isPageVisible, isPlatoVideoReady, isSimoneDeBeauvoirVideoReady, isIrisMurdochVideoReady, isErichFrommVideoReady]);
  
  return (
    <div className='Carousel-theme'>
      <h2>Living in Love and Relationships</h2>
      <div className='Philosophers-in-theme'>
        <div className='Group1'>
          <div className="Philosopher">
            <MemoizedPhilosopherImage src={Plato} alt="Plato" style={{ display: isPlatoVideoReady ? 'none' : 'block' }}/>
            <video
              ref={platoVideoRef}
              src={PlatoVideo}
              muted
              playsInline
              onCanPlayThrough={() => {
                setPlatoVideoReady(true);
              }}
              style={{ display: isPlatoVideoReady ? 'block' : 'none'}}
              className='Philosopher-intro-video'
            />
            <h3>Plato</h3>
          </div>
          <div className="Philosopher">
            <MemoizedPhilosopherImage src={SimoneDeBeauvoir} alt="Simone de Beauvoir" style={{ display: isSimoneDeBeauvoirVideoReady ? 'none' : 'block' }}/>
            <video
              ref={simoneVideoRef}
              src={SimoneDeBeauvoirVideo}
              muted
              playsInline
              onCanPlayThrough={() => {
                setSimoneDeBeauvoirVideoReady(true);
              }}
              style={{ display: isSimoneDeBeauvoirVideoReady ? 'block' : 'none'}}
              className='Philosopher-intro-video'
            />
            <h3>Simone de Beauvoir</h3>
          </div>
        </div>
        <div className='Group2'>
          <div className="Philosopher">
            <MemoizedPhilosopherImage src={IrisMurdoch} alt="Iris Murdoch" style={{ display: isIrisMurdochVideoReady ? 'none' : 'block' }}/>
            <video
              ref={irisVideoRef}
              src={IrisMurdochVideo}
              muted
              playsInline
              onCanPlayThrough={() => {
                setIrisMurdochVideoReady(true);
              }}
              style={{ display: isIrisMurdochVideoReady ? 'block' : 'none'}}
              className='Philosopher-intro-video'
            />
            <h3>Iris Murdoch</h3>
          </div>
          <div className="Philosopher">
            <MemoizedPhilosopherImage src={ErichFromm} alt="Erich Fromm" style={{ display: isSimoneDeBeauvoirVideoReady ? 'none' : 'block' }}/>
            <video
              ref={erichVideoRef}
              src={ErichFrommVideo}
              muted
              playsInline
              onCanPlayThrough={() => {
                setErichFrommVideoReady(true);
              }}
              style={{ display: isErichFrommVideoReady ? 'block' : 'none'}}
              className='Philosopher-intro-video'
            />
            <h3>Erich Fromm</h3>
          </div>
        </div>
      </div>
      <p className='Theme-description' id='Theme-description' >Dive into philosophical perspectives on love, relationships, and human connection, and understand how these thinkers of love believe you should approach your love life</p>
      <button className='Start-conversation-button' onClick={handleStartConverseClick}> <img src={White_chat_bubble} alt="White chat bubble"/> Start Conversation </button>
      <ConfirmationModal 
        isOpen={isConfirmationModalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        philosopherName={name}
      />
    </div>
  );
};

export default LivingInLoveAndRelationships;