import './App.css';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import QuestionsSection from './components/QuestionsSection';
import SettingsSection from './components/SettingsSection';
import ProTipsSection from './components/ProTipsSection';
import ChatGroupModal from './components/ChatGroupModal';
import logo_no_BG from './images/Logo-clean-size-uptimized.svg';
import Thoger_Underskrift from './images/Thoger_Underskrift_small.png'
import Send_Arrow from './images/icons/Send_Arrow.svg'
import { formatConversationHistory } from './utils/formatConversationHistory';
import { addLights } from './utils/addLights';
import QuestionsIconDefault from './images/Sidebar-icons/Questions_DarkGrey.svg';
import Grey_group_of_friends from './images/icons/Grey_group_of_friends.svg';
import Vibrant_gradient_group_of_friends from './images/icons/Vibrant_gradient_group_of_friends.svg'
import QuestionsIconActive from './images/Sidebar-icons/Questions_BluePurple.svg';
import ProTipsIconDefault from './images/Sidebar-icons/ProTips_DarkGrey.svg'
import ProTipsIconActive from './images/Sidebar-icons/ProTips_BluePurple.svg'
import SettingsIconDefault from './images/Sidebar-icons/Settingss_DarkGrey.svg';
import SettingsIconActive from './images/Sidebar-icons/Settings_BluePurple.svg';
import PhilosopherCatalog from './components/PhilosopherCatalog';
import PhilosopherDetailModal from './components/PhilosopherDetailModal';
import LandingPage from './components/LandingPage';
import LoadingButton from './components/LoadingButton';
import animationData from './LottieFiles/Dots-bounce.json';
import sphereAnimation from './LottieFiles/Sphere-light.json';
import { markdown } from 'markdown';
import { ChosenPhilosophersContext } from './context/ChosenPhilosophersContext';

//Importing all philosophers AI personalities
import { createmChain } from './AIs/moderator';
import { createmaChain } from './AIs/marcusAurelius';
import { createsdbChain } from './AIs/simoneDeBeauvoir';
import { createcChain } from './AIs/confucius';
import { createfnChain } from './AIs/friedrichNietzsche';
import { createikChain } from './AIs/immanuelKant';
import { createrdChain } from './AIs/reneDescartes';
import { createdhChain } from './AIs/davidHume';
import { createwvoqChain } from './AIs/wVOQuine';
import { createsChain } from './AIs/socrates';
import { createltChain } from './AIs/laoTzu';
import { createskChain } from './AIs/sorenKierkegaard';
import { createdChain } from './AIs/diogenes';
import { createasChain } from './AIs/arthurSchoupenhauer';
import { createacChain } from './AIs/albertCamus';
import { createaChain } from './AIs/aristotle';
import { createhaChain } from './AIs/hannahArendt';
import { createpChain } from './AIs/plato';
import { createswChain } from './AIs/simoneWeil';
import { createimChain } from './AIs/irisMurdoch';
import { createmmChain } from './AIs/maryMidgley';
import { createepictChain } from './AIs/epictetus';
import { createpfChain } from './AIs/philippaFoot';
import { createjpsChain } from './AIs/jean-PaulSatre';
import { createarChain } from './AIs/aynRand';
import { creategemaChain } from './AIs/gEMAnscombe';
import { createjbChain } from './AIs/jeremyBentham';
import { createefChain } from './AIs/erichFromm';
import { createepicuChain } from './AIs/epicurus';
import { createmimuChain } from './AIs/miyamotoMusashi';
import { createnmChain } from './AIs/niccoloMachiavelli';
import { creategwfhChain } from './AIs/gwfHegel';
import { createkmChain } from './AIs/karlMarx';
import { createjlChain } from './AIs/johnLocke';
import { createlwChain } from './AIs/ludwigWittgenstein';
import { createjsmChain } from './AIs/johnStuartMill';
import { createmdmChain } from './AIs/micheldeMontaigne';
import { creategdChain } from './AIs/gillesDeleuze';
import { createseChain } from './AIs/seneca';
import { createmwChain } from './AIs/maryWollstonecraft';
import { createmfChain } from './AIs/michelFoucault';
import { createbhChain } from './AIs/bellhooks';


function App() {
  const textareaRef = useRef(null);
  const chatWindowRef = useRef(null);
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);
  const lastScrollTop = useRef(0);
  const [userInput, setUserInput] = useState('');
  const [currentUserQuestion, setCurrentUserQuestion] = useState('');
  const [conversationHistory, setConversationHistory] = useState([]);
  const [streamingResponse, setStreamingResponse] = useState({author:'', text: ''});
  const [speakerSequence, setSpeakerSequence] = useState([]);
  const [currentSpeakerIndex, setCurrentSpeakerIndex] = useState(0);
  const [isQuestionsOpen, setIsQuestionsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isProTipsOpen, setIsProTipsOpen] = useState(false);
  const [lastKnownHeight, setLastKnownHeight] = useState(0);
  const [isPhilosopherCatalogVisible, setIsPhilosopherCatalogVisible] = useState(true);
  const [isPhilosophersResponding, setIsPhilosophersResponding] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const apiKeyEntered = !!apiKey;
  const [isApiKeyLocked, setApiKeyLocked] = useState(false);
  const [philosophers, setPhilosophers] = useState('');
  const [mChain, setmChain] = useState('');
  const [isChatGroupModalOpen, setIsChatGroupModalOpen] = useState(false)
  const { selectedPhilosophers, selectedCount, counterOfTimesPhilosophersHaveBeenChanged } = useContext(ChosenPhilosophersContext);

  const handleSetApiKey = (newKey) => {
    setApiKey(newKey)
    setApiKeyLocked(true)
  }

  const handleChangeApiKey = (e) =>{
    e.preventDefault();
    setApiKeyLocked(false)
  }

  const handleClickOnLogo = () => {
    setIsPhilosopherCatalogVisible(true)
    setIsQuestionsOpen(false)
  }

  useEffect(() => {
    if (apiKey.startsWith("sk") && isApiKeyLocked){
      
      // Pass the selected philosophers to update the moderator chain
      const philosopherList = selectedPhilosophers.map((philosopher, index) => `${index + 1}. ${philosopher.name}`).join('\n');
      console.log('This is what is kept within the philosopherList passed to the moderator chain: ', philosopherList)
      const moderater = createmChain(apiKey, philosopherList)
      setmChain(moderater)

      const chains = {};
      selectedPhilosophers.forEach((philosopher, index) => {
        switch (philosopher.name) {
          case 'Marcus Aurelius':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createmaChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Confucius':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createcChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Simone de Beauvoir':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createsdbChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Friedrich Nietzsche':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createfnChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Immanuel Kant':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createikChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'René Descartes':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createrdChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'David Hume':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createdhChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'W.V.O. Quine':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createwvoqChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Socrates':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createsChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Lao Tzu':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createltChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Søren Kierkegaard':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createskChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Diogenes':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createdChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Arthur Schopenhauer':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createasChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Albert Camus':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createacChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Aristotle':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createaChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Hannah Arendt':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createhaChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Plato':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createpChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Simone Weil':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createswChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Iris Murdoch':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createimChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Mary Midgley':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createmmChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Epictetus':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createepictChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Philippa Foot':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createpfChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Jean-Paul Sartre':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createjpsChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Ayn Rand':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createarChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'G.E.M. Anscombe':
            chains[`${index + 1}`] = { name: philosopher.name, chain: creategemaChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Jeremy Bentham':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createjbChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Erich Fromm':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createefChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Epicurus':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createepicuChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Miyamoto Musashi':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createmimuChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Niccolò Machiavelli':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createnmChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'G.W.F. Hegel':
            chains[`${index + 1}`] = { name: philosopher.name, chain: creategwfhChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Karl Marx':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createkmChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'John Locke':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createjlChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Ludwig Wittgenstein':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createlwChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'John Stuart Mill':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createjsmChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Michel de Montaigne':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createmdmChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Gilles Deleuze':
            chains[`${index + 1}`] = { name: philosopher.name, chain: creategdChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Seneca':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createseChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Mary Wollstonecraft':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createmwChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'Michel Foucault':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createmfChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          case 'bell hooks':
            chains[`${index + 1}`] = { name: philosopher.name, chain: createbhChain(apiKey, philosopherList), sp_image: philosopher.sp_image };
            break;
          // Add other philosophers here...
          default:
            break;
        }
      });

      setPhilosophers(chains);
    }
  }, [apiKey, isApiKeyLocked, selectedCount, selectedPhilosophers])

  // Adds more lights if the scrollheight of the thread becomes so large it almost extends the size of the lights (5 times viewport size)
  const addLightsBasedOfHeight = () => {
    const threadLayer = document.querySelector('.Thread')
    const currentHeight = threadLayer.scrollHeight
    const windowHeight = window.innerHeight
    const threshold = windowHeight * 3 * 0.9

    if (currentHeight > lastKnownHeight + threshold){
      addLights()
      setLastKnownHeight(currentHeight)
    }
  }

  // Function to combine the HTML textarea with my react approach
  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  }

  // Functions that toggles the respectively questions, Settings section and ChatGroup Modal
  const toggleChatGroupModal = () => {
    setIsChatGroupModalOpen(!isChatGroupModalOpen);
  };

  // I made this thing to open the Chat Group In Case that a philosopher is added without starting the chat. I found that the condition for the counter had to be two because the app aparently counts one on rendering the first time.
  useEffect(() => {
    if (counterOfTimesPhilosophersHaveBeenChanged === 2 && isPhilosopherCatalogVisible) {
      setIsChatGroupModalOpen(true)
    }
  }, [counterOfTimesPhilosophersHaveBeenChanged])

  
  const toggleQuestions = () => {
    setIsQuestionsOpen(!isQuestionsOpen);
    setIsSettingsOpen(false)
    setIsProTipsOpen(false)
  };

  const toggleSettings = () => {
    setIsSettingsOpen(!isSettingsOpen);
    setIsQuestionsOpen(false);
    setIsProTipsOpen(false)
  };

  const toggleProTips = () => {
    setIsProTipsOpen(!isProTipsOpen);
    setIsQuestionsOpen(false);
    setIsSettingsOpen(false)
  };

  // function to submit a question from the questions section
  const handleQuestionSelect = (question) => {
    if (isPhilosophersResponding) return;
    setUserInput(question);
    handleSubmitQuestionCard(question);
  };
  
  const handleSubmitQuestionCard = async (questionFromCard) => {
    setIsPhilosopherCatalogVisible(false)
    setIsPhilosophersResponding(true)
    setIsAutoScrollEnabled(true)
    //Check if there is existing conversation history
    const introText = conversationHistory.length > 0 ? "Alright! Now I want to ask a new question. " : "";
    const userQuestion = `${introText}${questionFromCard}`;
    setUserInput(''); // Clear the input field
    setCurrentUserQuestion(userQuestion);

    // Add the user's question to the conversation history
    setConversationHistory(prev => [...prev, { text: userQuestion, author: 'You' }]);

    // Invoke moderators chain
    try{
      const sequenceString = await mChain.invoke({
        question: userQuestion,
        history: formatConversationHistory(conversationHistory)
      })
      const sequenceArray = sequenceString.split(',').map(Number);
      setSpeakerSequence(sequenceArray)
      setCurrentSpeakerIndex(0);
    } catch (error){
      console.error('Error invoking the moderators chain:', error);
    }
  };


  // Function that can be called in to start the streaming of a philosopher
  const streamPhilosopherResponse = async (question, author, chain, onStreamingComplete) => {
    try{
      const stream = await chain.stream({
        question: question,
        history: formatConversationHistory(conversationHistory),
      });

      let partialResponse = '';

      for await (const chunk of stream) {
        partialResponse += chunk;
        setStreamingResponse({ author, text: partialResponse });
      }

      // Once streaming is complete, add the full response to the conversation history
      setConversationHistory(prev => [...prev, { text: partialResponse, author }]);
      // Clear streaming response
      setStreamingResponse({ author: '', text: '' });
      if (onStreamingComplete && typeof onStreamingComplete === 'function') {
      onStreamingComplete(); // Call the callback function
      }
    } catch (error) {
      console.error(`Error Streaming resonse from ${author}: `, error);
    }
  };

  //This effect is responsible for enlarging the textareafield with the users prompt everytime a user sends a message
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.addEventListener("keyup", e => {
        textarea.style.height = 'auto';
        let scHeight = e.target.scrollHeight;
        textarea.style.height = `${scHeight}px`;
      });

      return () => {
        textarea.removeEventListener("keyup", e => {
          textarea.style.height = 'auto';
          let scHeight = e.target.scrollHeight;
          textarea.style.height = `${scHeight}px`;
        });
      };
    }
  }, []);


  // The two underlying useEffects 1. and 2. are the ones responsible for scrolling the window to the bottom everytime a new message enters the chathistory but dissabling the automatic scroll on the condition that the user scrolls, upwards.
  useEffect(() => { // useEffect 1. - This is the useEffect responcible for setting the condition that if scrolling upwards autoscrolling is dissabled. However Autoscrolling is enabled when a new question is asked.
    const chatWindow = chatWindowRef.current;

    const handleScroll = () => {
      if (chatWindow) {
        const currentScrollTop = chatWindow.scrollTop;
        const atBottom = chatWindow.scrollHeight - chatWindow.scrollTop === chatWindow.clientHeight;

        // Only disable auto-scroll if the user scrolls upward
        if (currentScrollTop < lastScrollTop.current && !atBottom) {
          setIsAutoScrollEnabled(false);
        }

        // Update the last scroll position
        lastScrollTop.current = currentScrollTop;
      }
    };

    if (chatWindow) {
      chatWindow.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (chatWindow) {
        chatWindow.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);
  useEffect(() => { // useEffect 2. - This is the useEffect that makes the page scroll automatically.
    addLightsBasedOfHeight();
    const chatWindow = chatWindowRef.current;
    if (chatWindow && isAutoScrollEnabled) {
      chatWindow.scrollTo({
        top: chatWindow.scrollHeight,
        behavior: 'smooth' // Optional: define the scrolling behavior
      });
    }
  }, [streamingResponse, isAutoScrollEnabled]);

  //This is the function that is responsible for the paralax scrolling effect
  useEffect(() =>{
    const thread = chatWindowRef.current;
    const backgroundLayer = document.querySelector('.Diffused-lights-container')
    if (thread && backgroundLayer) {
      const handleScroll = () => {
        const scrollPosition = thread.scrollTop
        const backgroundPosition = scrollPosition * -0.33
        backgroundLayer.style.transform = `translateY(${backgroundPosition}px)`
      }

      thread.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        thread.removeEventListener('scroll', handleScroll)
      }
    }
  },[])


  useEffect(() => {
    // Function to invoke the next philosopher's chain
    const invokeNextPhilosopher = async (index) => {
      if (!isPhilosophersResponding){
        console.log("Philosophers where just porhibited to be invoked")
        return;
      }
      console.log('This is the speakerSequence: ', speakerSequence)
      const speakerId = speakerSequence[index];
      console.log("This is the current speakerid:", speakerId)
      const philosopherInfo = philosophers[speakerId.toString()];
      if (philosopherInfo) {
        await streamPhilosopherResponse(
          currentUserQuestion,
          {
            name: philosopherInfo.name,
            sp_image: philosopherInfo.sp_image
          },
          philosopherInfo.chain,
          () => {
            // Once streaming is complete, trigger the next philosopher in line if there is one
            // if not set the state of isPhilosophersResponding to false to activate the ability to send new messages
            if (index === speakerSequence.length - 1){
              console.log("Now the PhilosopherResponding state is being set to false")
              setIsPhilosophersResponding(false)
            } 
            setCurrentSpeakerIndex(index + 1);
          }
        );
      }
    };

    if (speakerSequence.length > 0 && currentSpeakerIndex < speakerSequence.length) {
      invokeNextPhilosopher(currentSpeakerIndex);
    }
  }, [speakerSequence, conversationHistory]);


  //This is the handleSubmit for what is triggered after sending something from the textarea
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPhilosopherCatalogVisible(false);
    setIsPhilosophersResponding(true)
    setIsAutoScrollEnabled(true)
    const userQuestion = userInput;
    setUserInput(''); // Clear the input field
    setCurrentUserQuestion(userQuestion)

    // Add the user's question to the conversation history
    setConversationHistory(prev => [...prev, { text: userQuestion, author: 'You' }]);

    // Invoke moderators chain
    try{
      const sequenceString = await mChain.invoke({
        question: userQuestion,
        history: formatConversationHistory(conversationHistory)
      })
      console.log("This is the generated string: ",sequenceString)
      const sequenceArray = sequenceString.split(',').map(Number);
      setCurrentSpeakerIndex(0);
      setSpeakerSequence(sequenceArray)
    } catch (error){
      console.error('Error invoking the moderators chain:', error);
    }
  }



  return (
    <Router>
      <div className="App">
        <LandingPage onSetApiKey={handleSetApiKey} animationData={sphereAnimation} apiKeyEntered={apiKeyEntered} />
        <div className="Side-bar">
          <div className='Modules'>
            <img src={logo_no_BG} className="App-logo" alt="PhiloSphere logo" onClick={handleClickOnLogo}/>
            <div className='Side-section-toggles'>
              <button className={`Side-button ${isChatGroupModalOpen ? 'active' : ''}`} onClick={toggleChatGroupModal}>
                <div className='Side-selection-indicator'></div>
                <div className='Side-button-image-n-text'>
                  <img src={isChatGroupModalOpen ? Vibrant_gradient_group_of_friends : Grey_group_of_friends} className="Questions-icon" als="Questions"/>
                  {selectedCount > 0 && (
                    <span className='Selected-philosophers-counter'>
                      <span>{selectedCount}</span>
                    </span>
                  )}
                  <p className='Side-button-text'>Chat Group</p>
                </div>
              </button>
              <button className={`Side-button ${isProTipsOpen ? 'active' : ''}`} onClick={toggleProTips}>
                <div className='Side-selection-indicator'></div>
                <div className='Side-button-image-n-text'>
                  <img src={isProTipsOpen ? ProTipsIconActive : ProTipsIconDefault} className="Information-icon" als="Pro Tips"/>
                  <p className='Side-button-text'>Pro Tips</p>
                </div>
              </button>
              <button className={`Side-button ${isSettingsOpen ? 'active' : ''}`} onClick={toggleSettings} id={`Settings-button-${!isPhilosopherCatalogVisible ? 'hidden' : ''}`}>
                <div className='Side-selection-indicator'></div>
                <div className='Side-button-image-n-text'>
                  <img src={isSettingsOpen ? SettingsIconActive : SettingsIconDefault} className="Settings-icon" als="Settings"/>
                  <p className='Side-button-text'>Settings</p>
                </div>
              </button>
              {!isPhilosopherCatalogVisible ? (
                <>
                  <div className='Side-bar-divider'></div>
                  <button className={`Side-button ${isQuestionsOpen ? 'active' : ''}`} onClick={toggleQuestions}>
                    <div className='Side-selection-indicator'></div>
                    <div className='Side-button-image-n-text'>
                      <img src={isQuestionsOpen ? QuestionsIconActive : QuestionsIconDefault} className="Questions-icon" als="Questions"/>
                      <p className='Side-button-text'>Questions</p>
                    </div>
                  </button>
                </>
              ) : null}
                
            </div>
          </div>
          <a href='https://www.thoughtfulcreationeer.com/' target='_blank'>
          <img src={Thoger_Underskrift} className='Thoger-Underskrift' alt='Thøger Kappel Signature' />
          </a>
        </div>
        <SettingsSection isVisible={isSettingsOpen} apiKey={apiKey} onSetApiKey={handleSetApiKey} onChangeApiKey={handleChangeApiKey} isLocked={isApiKeyLocked}/>
        <QuestionsSection isVisible={isQuestionsOpen} shouldCardsDissable={isPhilosophersResponding} onQuestionSelect={handleQuestionSelect}/>
        <ProTipsSection isVisible={isProTipsOpen}/>
        <ChatGroupModal 
          isOpen={isChatGroupModalOpen} 
          onClose={() => setIsChatGroupModalOpen(false)} 
          startConversation={() => {
            if (selectedCount > 0) {
              setIsPhilosopherCatalogVisible(false);
              setIsChatGroupModalOpen(false);
              setIsQuestionsOpen(true)
              setCurrentUserQuestion("Please, give the visitor of PhiloSphere a personal greeting fitting for your charactor and personality and then proceed to introduce yourself and the themes your ideas revolve around. Then end by asking what they would want to talk about. Your greeting should be in English");
              const updatedSpeakerSequence = selectedPhilosophers.map((_, index) => index + 1)
              setCurrentSpeakerIndex(0);
              setSpeakerSequence(updatedSpeakerSequence);
              setIsPhilosophersResponding(true);
            }
          }}
        />
        <div className='Chat-elements'>
          <div className="Overflow"></div>
          <PhilosopherCatalog isVisible={isPhilosopherCatalogVisible} setIsQuestionsOpen={setIsQuestionsOpen} setIsPhilosopherCatalogVisible={setIsPhilosopherCatalogVisible} setCurrentUserQuestion={setCurrentUserQuestion} setSpeakerSequence={setSpeakerSequence} setIsPhilosophersResponding={setIsPhilosophersResponding} setCurrentSpeakerIndex={setCurrentSpeakerIndex} conversationHistory={conversationHistory}/>
          <div className="Thread" id="Thread" ref={chatWindowRef}>
            {conversationHistory.map((message, index) => {
              return(
                <Message key={index} text={message.text} author={message.author} />
              )
            })}
            {streamingResponse.author && (
              <StreamingMessage key="streaming" text={streamingResponse.text} author={streamingResponse.author} />
            )}
          </div>
          <div className='Blur-screen'></div>
          <div className='Diffused-lights-container'>
            <div className='Light-1'></div>
            <div className='Light-2'></div>
            <div className='Light-3'></div>
            <div className='Light-4'></div>
            <div className='Light-5'></div>
            <div className='Light-6'></div>
            <div className='Light-7'></div>
            <div className='Light-8'></div>
            <div className='Light-9'></div>
          </div>
          <div className="Input-n-notification">
            <form id="form" className="Input-container" onSubmit={handleSubmit}>
              <textarea rows="1" className="user-input" type="text" id="user-input" placeholder="Converse with the wise that came before" ref={textareaRef} value={userInput} onChange={handleInputChange} required 
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey && !isPhilosophersResponding) {
                    e.preventDefault(); // Prevents the default action to allow for a new line in case of Shift + Enter
                    handleSubmit(e);
                  }
                }}
              />
              <div className='Button-wrapper'>
                {isPhilosophersResponding ? (
                  < LoadingButton animationData={animationData} />
                ) : (
                  <button id="submit-btn" className="Submit-button">
                    Send
                    <img src={Send_Arrow} className="send-btn-icon" alt='Paperplane icon'/>
                  </button>
                )}
              </div>
            </form>
            <p> The chat responds in this conversation are AI-generated </p>
          </div>
        </div>
        <Routes>
          <Route path="/:philosopherName" element={<PhilosopherDetailModal setIsPhilosopherCatalogVisible={setIsPhilosopherCatalogVisible} />}/>
        </Routes>
      </div>
    </Router>
  );
}



//When the message history updates the Message component reders a speech bubble with the philosopher's image
const Message = ({ text, author }) => {
  const createMarkup = (text) => {
    return { __html: markdown.toHTML(text) }
  }

  return(
    <div className={`Message-${author === 'You' ? 'user-message' : 'ai-message'}`} >
      {author !== 'You' && <p className='Message-author'>{author.name}</p>}
      <div className='Pictue-n-message'>
        {author !== 'You' && <img src={author.sp_image} alt={author.name} className='Philosopher-image' />}
        <div className='Speech-bubble'>
          <p className='Message-text' dangerouslySetInnerHTML={createMarkup(text)}></p>
        </div>
      </div>
    </div>
  )
}

//When the streaming starts updates the Message component reders a speech bubble with the philosopher's image
const StreamingMessage = ({ text, author }) => {
  const createMarkup = (text) => {
    return { __html: markdown.toHTML(text) }
  }
  return(
    <div className={'Message-streaming-ai-message'}>
      <p className='Message-author'>{author.name}</p>
      <div className='Pictue-n-message'>
        {author !== 'You' && <img src={author.sp_image} alt={author.name} className='Philosopher-image' />}
        <div className='Speech-bubble'>
          <div className='Message-text'>
            <span className="fade-in-token" dangerouslySetInnerHTML={createMarkup(text)}></span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App;