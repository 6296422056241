// LoadingButton.js

import lottie from 'lottie-web';
import {useEffect, useRef} from 'react';
import '../App.css';


const LoadingButton = ({animationData}) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current && !containerRef.current.hasChildNodes()) {
      const animation = lottie.loadAnimation({
        container: containerRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      });
  
      return () => animation.destroy();
    }
  }, []);

  return (
    <button className="Loader-button" disabled={true}>
      <div ref={containerRef}></div>
    </button>
  );
};

export default LoadingButton;
