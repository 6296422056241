// PhilosopherDetailModal.js
import React, { useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { philosophers } from  '../PhilosophersData'
import { ChosenPhilosophersContext } from '../context/ChosenPhilosophersContext';

// Images Import
import Grey_X from '../images/icons/Darkgrey_X.svg';
import DividerLine from './DividerLine.js';
import Add_to_group from '../images/icons/Add_to_group.svg'
import Remove_from_group from '../images/icons/Remove_from_group_black.svg'
import White_chat_bubble from '../images/icons/White_chat_bubble.svg';

const PhilosopherDetailModal = () => {
  const { philosopherName } = useParams();
  const { selectedPhilosophers, togglePhilosopher } = useContext(ChosenPhilosophersContext);
  const navigate = useNavigate();

  const philosopher = philosophers.find(p => {
    return p.name.toLowerCase().replace(/\s+/g, '-') === philosopherName.toLowerCase();
  });

  if (!philosopher) {
    return <div> Philosopher was not found </div>
  }

  const handleClose = () => {
    navigate(-1)
  }
  
  const isInGroup = selectedPhilosophers.includes(philosopher)

  return ( 
    <div className='Modal-overlay' id='Overlay-for-the-philosopher-page'> 
      <div className='Modal-content'>
        <div className='Images-n-quote-section'>
          <div className='Backdrop-n-qoute'>
            <img className='Backdrop' src={philosopher.backgroundColor} alt='Backdrop'/>
            <p><q>{philosopher.bestQuote}</q></p>
          </div>
          <img className='Profile-picture' src={philosopher.ff_image}/>
          <button onClick={handleClose} className="X-button"> <img src={Grey_X} alt='Close'/> </button>
        </div>
        <div className='Top-information'>
          <div className='Card-information-n-country'>
            <h2 className='Philosopher-name'>{philosopher.name}</h2>
            <div className='Info-container'>
              <h3>{philosopher.epithet}</h3>
              <div className='Lifespan-n-nationality'>
                <h4>{philosopher.lifeSpan}</h4>
                <span className='Dot-spacer'></span>
                <h4>{philosopher.nationality}</h4>
              </div>
              <div className='Tags'>
                <p>{philosopher.descriptiveWords[0]}</p>
                <span className='Dot-spacer' ></span>
                <p>{philosopher.descriptiveWords[1]}</p>
                <span className='Dot-spacer' ></span>
                <p>{philosopher.descriptiveWords[2]}</p>
              </div>
              <div className='Buttons'>
                {/* <button className='Start-conversation-button'> <img src={White_chat_bubble}/> Converse </button> */}
                {isInGroup ?
                  <button className='Secondary-outlined-button' id='Remove-philosopher' onClick={() => togglePhilosopher(philosopher)}> <img src={Remove_from_group}/> Remove </button>
                  :
                  <button className='Secondary-outlined-button' onClick={() => togglePhilosopher(philosopher)}> <img src={Add_to_group}/> Group </button>
                }
              </div>
            </div>
          </div>
          <div className='Life-accomplishments'>
            <p>Life achievements:</p>
            <ol>
              {philosopher.accomplishments.map((accomplishment, index) => (
                <li key={index}>{accomplishment}</li>
              ))}
            </ol>
          </div>
        </div>
        {/* <DividerLine /> */}
      </div>
    </div>
  )
};

export default PhilosopherDetailModal;


