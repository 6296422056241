// PhilosopherInfo.js
import React from 'react';

const ConfirmationModal = ({ isOpen, onConfirm, onCancel, philosopherName }) => {
  if (!isOpen) return null; 

  return ( 
    <div className='Modal-overlay' id='Modal-overlay'> 
      <div className='Modal-content'>
        <h2>Are you sure?</h2>
        <p className='Decision-description'>You have already added philosophers to your chat group. Do you want to replace them and continue to the chat with just {philosopherName}?</p>
        <div>
          <button onClick={onConfirm}>Yes, replace</button>
          <button onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  )
};

export default ConfirmationModal;


