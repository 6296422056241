// components/QuestionsSection.js
import React from 'react';
import '../App.css';

const QuestionsSection = ({ onQuestionSelect, isVisible, shouldCardsDissable}) => {
  const questions = [
    "What great mistakes do people often make not realizing it?",
    "Everyone, during the life you lived, who inspired you and why?",
    "How do you think one should live, to live a good life?",
    "As education evolves, what principles should guide the development of curricula that prepare students for the complexities of modern life?",
    "Everyone, during the life you lived, what have been your single greatest achievement",
    "What defines true freedom in our modern day society, and how should I as an individual achieve it?",
    "Can we ever achieve a perfect society, or are utopian ideals inherently flawed?",
    "What do you think is the most pressing ethical dilemma facing humanity today?",
    "What do you believe is the value of relationships?",
    "What teachings do you offer for finding balance between work and personal life?",
    "In a world of accelerating change, how should I react?",
    "What responsibility do you believe I have in climate change and environmental degradation?",
    "In the context of lifelong learning and personal growth, how does each of your philosophies contribute to our understanding of education's purpose?",
    "What roles do silence and solitude play in achieving personal growth and understanding in today's hyper-connected world?",
    "How should society navigate the balance between innovation and the preservation of cultural and historical heritage?",
    "As global politics becomes more polarized, what guidance do each of your philosophies offer to cultivate understanding and cooperation?",
    "What criteria should determine whether an action is just?",
    "Is there intrinsic value in art, or is its value always subjective?",
    "What do you think about the fact that your work have been used, so many years after your death, to bring you to life within this experience?",
    // ...other questions
  ];

  return (
    <div className={`Questions-section-${isVisible ? 'visible' : 'hidden'}`}>
        <h2>Questions</h2>
        <div className='Q-cards-section'>
            <div className='Info-line'>
              <span>i</span>
              <p>Click on a question to ask it</p>
            </div>
            <div className='Clickable-questions'>
              {questions.map((question, index) => (
                <div key={index} className={`Question-card ${shouldCardsDissable ? 'disabled' : ''}`} onClick={() => onQuestionSelect(question)} tabindex="0" role="button">
                    {question}
                </div>
              ))}
            </div>
        </div>
    </div>
  );
};

export default QuestionsSection;
