/**
 * When @const {mChain}. is invoked with LangChain, OpenAI recieves a prompt from the user and generates the sequence of philosophers that should answer.
 * @param {String} history - formatted string representing the conversation history.
 * @param {String} question  - The users prompt.
 */

import { PromptTemplate } from "@langchain/core/prompts";
import { StringOutputParser } from "@langchain/core/output_parsers";
import { RunnableSequence } from "@langchain/core/runnables";
import { createGpt4o } from "../utils/gpt4o";

const createmChain = (apiKey, philosopherList) => {
  const gpt4o = createGpt4o(apiKey);


  const template = `You will receive a question, and based on this, you will give me a sequence of numbers, separated by a comma, referring to some philosopher(s) that should answer the question like this "3,2", "2,1,3,4" "4,3,1", "4,3,2,1", "3,4,3,4,3,4", "1,2,3,4,2,4" or "3,4,1,3,4,1,2". The referencing sequence or order of speakers, should unless otherwise specified in the users question, be of a length 
    between one and the number of philosophers present. Also unless otherwise specified in the users question no philosopher should be in the sequence more than once. The number "zero" can never be in the sequence. These are the philosophers: 
    
    "
      0. Placeholder
      ${philosopherList}
    "
    
    Here is an unordered list of rules that you must follow when creating your sequence of philosophers: 
    
    "
      - If a certain philosopher or more of the philosophers are specifically mentioned in the question, you will generate your sequence only including the specific numbers of the philosophers mentioned in that specific question. 
        like if you get the question "Simone de Beauvoir, what do you think" and her number is three your will only pick her number and if the questions is "Marcus Aurelius and Confucius, What do you think", and their numbers are 1 and 2, you will only pick "1,2".
      - If the question mentions "all", "everybody", "everyone", or in some other way imply that all philosophers should answer, please create a sequence consisting of all the numbers.
      - If the question mentions a certain order or like Montaigne, then Artistotle, then Lao Tzu and then Montaing and Artistotle again. Please pick their respective numbers with the philosophers numbers being used for the same amount of times they have been mena´taion
      - The conversation history might or might not be relevant for the sequence. 
        - The conversation history is relevant when one or more of the philosophers haven't been included in previous of questions. Pay attention to this if the user mentions "what about the rest of you", "What about you others", "what do you others think", 
          or the user in some other way insinuate that they would want to hear from the philosophers that didn't answer on the previous question, then you must choose esclusively the numbers for the philosophers that didn't answer that question. 
        - in situations where a follow-up question is directly related to the previous question or like asking for further clarification, expansion, or deeper understanding, you should choose the same philosophers numbers as before. An example of choosing the same person would be if asked "Could you please expand on this?" or "Could you elaborate on that"?.

    "

    If none of the above rules apply for the question you were given, you will give your best guess for the most interesting sequence of only two philosophers to answer that question like this "4,1" or "3,2".

    This is the conversation history: "{history}".
    This is the question: "{question}"

    Make sure that you pay special attention if the question starts as "Alright! Now I want to ask a new question. Everyone," Then you must generate a sequence using four numbers referencing all the philosophers like "4,3,2,1", "1,4,3,2" or "2,3,4,1".
    Don't reply with anything else than a sequence of numbers separated by commas!`;

  const prompt = PromptTemplate.fromTemplate(template);

  return RunnableSequence.from([
    prompt,
    gpt4o,
    new StringOutputParser()
  ]);
};

export { createmChain };
