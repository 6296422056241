// components/LandingPage.js
import lottie from 'lottie-web';
import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import logo_no_BG from '../images/Logo-clean-size-uptimized.svg';
import Thoger_Underskrift from '../images/Thoger_Underskrift_small.png'


const LandingPage = ({ onSetApiKey, animationData, apiKeyEntered }) => {
  const [localApiKey, setLocalApiKey] = useState('');
  const [keyEntered, setKeyEntered] = useState(false)
  const [errorMessage, setErrorMessage] = useState(' ');
  const sphereRef = useRef(null);
  const shadowRef = useRef(null);
  const backGroundRef = useRef(null);

  useEffect(() => {
    if (sphereRef.current && !sphereRef.current.hasChildNodes()) {
      lottie.loadAnimation({
        container: sphereRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      });
    }
    if (shadowRef.current && !shadowRef.current.hasChildNodes()) {
      lottie.loadAnimation({
        container: shadowRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      });
    }
    if (backGroundRef.current && !backGroundRef.current.hasChildNodes()) {
      lottie.loadAnimation({
        container: backGroundRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      });
    }
  }, []);

  const validateApiKey = (apiKey) => {
    if (apiKey.startsWith('sk-') && apiKey.length >= 32){
      setErrorMessage('');
      return true;
    } else {
      setErrorMessage('Please insert a valid OpenAI key')
      return false;
    }
  }

  const handleInputChange = (e) => {
    const input = e.target.value;
    setLocalApiKey(input);
    validateApiKey(input);
  }

  const handleEnterClick = (e) => {
    e.preventDefault();
    if (validateApiKey(localApiKey)) {
      setKeyEntered(true)
      onSetApiKey(localApiKey);
      localStorage.setItem('openAIKey', localApiKey)
      console.log("This is what is set as the localApiKey: ", localApiKey)
    }
  }

  return (
    <div className={`Landing-page ${apiKeyEntered ? 'fade-app-in' : ''}`} >
      <div className='Background-animated-light'>
        <div className='Animated-light' ref={backGroundRef}></div>
      </div>
      <div className='Background-overlay'>
        <div className={`Sphere ${apiKeyEntered ? 'enlarge' : ''}`} >
          <div className={`Sphere-content ${apiKeyEntered ? 'fade-ladningpage-content-out' : ''}`}>
            <p>Welcome to</p>
            <div>
              <img src={logo_no_BG} className="Logo" alt="PhiloSphere logo" />
              <h1>PhiloSphere</h1>
            </div>
            <h2>A microcosm to converse with the wise that came before</h2>
            <form className='OpenAI-section'>
              <input className={'OpenAI-APIkey-input'} type="text" placeholder='Insert OpenAI key to enter the sphere' value={localApiKey} onChange={handleInputChange} required minlength="32" title="Please enter a valid OpenAI API key starting with 'sk-' followed by at least 32 alphanumeric characters."/>
              <button className='Enter-button' onClick={handleEnterClick}>Enter</button>
            </form>
            <p className='Error-message'>{errorMessage}</p>
            <a href='https://www.youtube.com/watch?v=9f1kNqVxPrY' target='_blank'>Need help getting an OpenAI key?</a>
          </div>
          <div className='Animated-light' ref={sphereRef}></div>
        </div>
      </div>
      <div className={`Shadow ${apiKeyEntered ? 'fade-ladningpage-content-out' : ''}`} >
        <div className='Darkness'></div>
        <div className='Animated-light' ref={shadowRef}></div>
      </div>
      <a href='https://www.thoughtfulcreationeer.com/' target='_blank'>
        <img src={Thoger_Underskrift} className={`Thoger-Signature ${apiKeyEntered ? 'fade-ladningpage-content-out' : ''}`} alt='Thøger Kappel Signature' />
      </a>
    </div>
  );
};

export default LandingPage;