// This will be one of the themed pre-selected groups within the carousel above the fold as the user enters the sphere
import React, { useContext, useState, useMemo, useRef, useEffect } from 'react';
import { philosophers } from '../../PhilosophersData';
import { ChosenPhilosophersContext } from '../../context/ChosenPhilosophersContext';
import ConfirmationModal from '../ConfirmationModal';
import MemoizedPhilosopherImage from './MemoizedPhilosopherImages';


//Import pictures
import Aristotle from '../../images/marbleStatuePictures/FrontFacing/AristotleWhiteBG.jpg';
import LaoTzu from '../../images/marbleStatuePictures/FrontFacing/LaoTzuWhiteBG.jpg';
import Epicurus from '../../images/marbleStatuePictures/FrontFacing/EpicurusWhiteBG.jpg';
import MarcusAurelius from '../../images/marbleStatuePictures/FrontFacing/MarcusAureliusWhiteBG.jpg';
import White_chat_bubble from '../../images/icons/White_chat_bubble.svg';

// Import videos
import AristotleVideo from '../../videoes/Aristotle.mp4';
import LaoTzuVideo from '../../videoes/LaoTzu.mp4';
import EpicurusVideo from '../../videoes/Epicurus.mp4';
import MarcusAureliusVideo from '../../videoes/MarcusAurelius.mp4';

const PathwaysToAHappyLife = ({ setIsQuestionsOpen, setIsPhilosopherCatalogVisible, setCurrentUserQuestion, setSpeakerSequence, setIsPhilosophersResponding,setCurrentSpeakerIndex, currentThemeIndex, setAutoPlayOnPause }) => {
  const { setSelectedPhilosophers, selectedCount} = useContext(ChosenPhilosophersContext);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  // States for playing the videoes by change of CSS style from image to video in the case that the videos have been fully loaded and is ready to play seemlessly
  const [isAristotleVideoReady, setAristotleVideoReady] = useState(false);
  const [isLaoTzuVideoReady, setLaoTzuVideoReady] = useState(false);
  const [isEpicurusVideoReady, setEpicurusVideoReady] = useState(false);
  const [isMarcusAureliusVideoReady, setMarcusAureliusVideoReady] = useState(false);
  const [isPageVisible, setPageVisible] = useState(true);

  // Refs to access videos elements as means to play them from the start when a user go's to a new theme in the theme carousel
  const aristotleVideoRef = useRef(null);
  const laoTzuVideoRef = useRef(null);
  const epicurusVideoRef = useRef(null);
  const marcusAureliusVideoRef = useRef(null);

  // Variable for the modal to show if one wants to chat with these instead of what was allready in the basket
  const name = 'Aristotle, Lao Tzu, Epicurus and Marcus Aurelius'

  // Variable that initiates the philosophers on the topic of the conversation
  const prompt = "Please, give the visitor of PhiloSphere a happy personal greeting fitting for your character. Then introduce the theme of finding happiness in life, tell a story of how you have experienced happiness yourself and how you've used your philosophic ideas for finding happyness in your life. Then end by asking what questions related to the topic they are pondering or what advice they are seeking. Answer in English until you've been told otherwise"

  const themedGroup = useMemo(() => [
    philosophers.find(p => p.name === 'Aristotle'),
    philosophers.find(p => p.name === 'Lao Tzu'),
    philosophers.find(p => p.name === 'Epicurus'),
    philosophers.find(p => p.name === 'Marcus Aurelius')
  ], [])

  const handleStartConverseClick = () => {
    if (selectedCount > 0) {
      setIsConfirmationModalOpen(true);
      setAutoPlayOnPause(true)
    } else {
      setIsPhilosophersResponding(true);
      setCurrentSpeakerIndex(0);
      setSelectedPhilosophers(themedGroup)
      setIsPhilosopherCatalogVisible(false)
      setCurrentUserQuestion(prompt);

      // Ensure selectedPhilosophers is updated before setting the sequence
      setTimeout(() => {
        const updatedSelectedPhilosophers = themedGroup;
        setSpeakerSequence(updatedSelectedPhilosophers.map((_, index) => index + 1));
      }, 100);
      setIsQuestionsOpen(true)
    }
  }

  const handleConfirm = () => {
    setIsPhilosophersResponding(true);
    setCurrentSpeakerIndex(0);
    setSelectedPhilosophers(themedGroup)
    setIsPhilosopherCatalogVisible(false)
    setCurrentUserQuestion(prompt);

    // Ensure selectedPhilosophers is updated before setting the sequence
    setTimeout(() => {
      const updatedSelectedPhilosophers = themedGroup;
      setSpeakerSequence(updatedSelectedPhilosophers.map((_, index) => index + 1));
    }, 100);
    setAutoPlayOnPause(false)
    setIsQuestionsOpen(true)
  }

  const handleCancel = () => {
    setIsConfirmationModalOpen(false)
    setAutoPlayOnPause(false)
  }

 // Function to pause videos when page is not visible
 const pauseVideos = () => {
  if (aristotleVideoRef.current) aristotleVideoRef.current.pause();
  if (laoTzuVideoRef.current) laoTzuVideoRef.current.pause();
  if (epicurusVideoRef.current) epicurusVideoRef.current.pause();
  if (marcusAureliusVideoRef.current) marcusAureliusVideoRef.current.pause();
};

// Function to play videos from the start when they are ready
const playVideosFromStart = () => {
  if (isAristotleVideoReady && aristotleVideoRef.current) {
    aristotleVideoRef.current.currentTime = 0;
    aristotleVideoRef.current.play().catch(error => console.log('Play request was interrupted:', error));
  }
  if (isLaoTzuVideoReady && laoTzuVideoRef.current) {
    laoTzuVideoRef.current.currentTime = 0;
    laoTzuVideoRef.current.play().catch(error => console.log('Play request was interrupted:', error));
  }
  if (isEpicurusVideoReady && epicurusVideoRef.current) {
    epicurusVideoRef.current.currentTime = 0;
    epicurusVideoRef.current.play().catch(error => console.log('Play request was interrupted:', error));
  }
  if (isMarcusAureliusVideoReady && marcusAureliusVideoRef.current) {
    marcusAureliusVideoRef.current.currentTime = 0;
    marcusAureliusVideoRef.current.play().catch(error => console.log('Play request was interrupted:', error));
  }
};

useEffect(() => {
  const handleVisibilityChange = () => {
    if (document.hidden) {
      setPageVisible(false);
      pauseVideos();
    } else {
      setPageVisible(true);
      playVideosFromStart();
    }
  };

  document.addEventListener('visibilitychange', handleVisibilityChange);

  return () => {
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  };
}, [isAristotleVideoReady, isLaoTzuVideoReady, isEpicurusVideoReady, isMarcusAureliusVideoReady]);

useEffect(() => {
  if (currentThemeIndex === 2 && isPageVisible) {
    playVideosFromStart();
  }
}, [currentThemeIndex, isPageVisible, isAristotleVideoReady, isLaoTzuVideoReady, isEpicurusVideoReady, isMarcusAureliusVideoReady]);
  
  return (
    <div className='Carousel-theme'>
      <h2>Pathways to a Happy Life</h2>
      <div className='Philosophers-in-theme'>
        <div className='Group1'>
          <div className="Philosopher">
            <MemoizedPhilosopherImage src={Aristotle} alt="Aristotle" style={{ display: isAristotleVideoReady ? 'none' : 'block' }}/>
            <video
              ref={aristotleVideoRef}
              src={AristotleVideo}
              muted
              playsInline
              onCanPlayThrough={() => {
                setAristotleVideoReady(true);
              }}
              style={{ display: isAristotleVideoReady ? 'block' : 'none'}}
              className='Philosopher-intro-video'
            />
            <h3>Aristotle</h3>
          </div>
          <div className="Philosopher">
            <MemoizedPhilosopherImage src={LaoTzu} alt="Lao Tzu" style={{ display: isLaoTzuVideoReady ? 'none' : 'block' }}/>
            <video
              ref={laoTzuVideoRef}
              src={LaoTzuVideo}
              muted
              playsInline
              onCanPlayThrough={() => {
                setLaoTzuVideoReady(true);
              }}
              style={{ display: isLaoTzuVideoReady ? 'block' : 'none'}}
              className='Philosopher-intro-video'
            />
            <h3>Lao Tzu</h3>
          </div>
        </div>
        <div className='Group2'>
          <div className="Philosopher">
            <MemoizedPhilosopherImage src={Epicurus} alt="Epicurus" style={{ display: isEpicurusVideoReady ? 'none' : 'block' }}/>
            <video
              ref={epicurusVideoRef}
              src={EpicurusVideo}
              muted
              playsInline
              onCanPlayThrough={() => {
                setEpicurusVideoReady(true);
              }}
              style={{ display: isEpicurusVideoReady ? 'block' : 'none'}}
              className='Philosopher-intro-video'
            />
            <h3>Epicurus</h3>
          </div>
          <div className="Philosopher">
            <MemoizedPhilosopherImage src={MarcusAurelius} alt="Marcus Aurelius" style={{ display: isMarcusAureliusVideoReady ? 'none' : 'block' }}/>
            <video
              ref={marcusAureliusVideoRef}
              src={MarcusAureliusVideo}
              muted
              playsInline
              onCanPlayThrough={() => {
                setMarcusAureliusVideoReady(true);
              }}
              style={{ display: isMarcusAureliusVideoReady ? 'block' : 'none'}}
              className='Philosopher-intro-video'
            />
            <h3>Marcus Aurelius</h3>
          </div>
        </div>
      </div>
      <p className='Theme-description' id='Theme-description' >Dive into timeless wisdom on happiness and fulfillment with these ancient greats. Engage in dialog on ideas of well-being, balance, and inner peace.</p>
      <button className='Start-conversation-button' onClick={handleStartConverseClick}> <img src={White_chat_bubble} /> Start Conversation </button>
      <ConfirmationModal 
        isOpen={isConfirmationModalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        philosopherName={name}
      />
    </div>
  );
};

export default PathwaysToAHappyLife;