// components/SettingsSection.js
import React, {useEffect, useState} from 'react';
import '../App.css';

const SettingsSection = ({ apiKey, isVisible, onSetApiKey, onChangeApiKey, isLocked }) => {
  const [localApiKey, setLocalApiKey] = useState(apiKey)

  useEffect(() => {
    const storedApiKey = localStorage.getItem('openAIKey');
    if (storedApiKey) {
      setLocalApiKey(storedApiKey)
      onSetApiKey(storedApiKey)
      console.log("The API-Key has been set with a locally stored API-key", storedApiKey)
    }
  }, [apiKey]);

  const handleInputChange = (e) => {
    setLocalApiKey(e.target.value)
  }

  const handleSetClick = (e) => {
    e.preventDefault();
    onSetApiKey(localApiKey);
    localStorage.setItem('openAIKey', localApiKey)
    console.log("This is what is set as the localApiKey: ", localApiKey)
  }

  return (
    <div className={`Settings-section-${isVisible ? 'visible' : 'hidden'}`}>
        <h2>Settings</h2>
        <form className='OpenAI-section'>
          <p>Set your OpenAI API key</p>
          <input className={`OpenAI-APIkey-input-${isLocked ? 'locked' : 'unlocked'}`} type="text" placeholder='Paste key here' value={localApiKey} onChange={handleInputChange} required disabled={isLocked}/>
          <div className='Buttons-container'>
            <button className={`Change-button-${isLocked ? 'active' : 'inactive'}`} onClick={onChangeApiKey}>Change</button>
            <button className='Set-button' onClick={handleSetClick}>Set</button>
          </div>
          <a href='https://www.youtube.com/watch?v=OB99E7Y1cMA' target='_blank'>Need help?</a>
        </form>
    </div>
  );
};

export default SettingsSection;
