// This will be one of the themed pre-selected groups within the carousel above the fold as the user enters the sphere
import React, { useState, useEffect, useMemo } from 'react';

// Importing components to be displayed in the carousel
import NavigatingLifesHardships from './NavigatingLifesHardships';
import FindingMeaningInLife from './FindingMeaningInLife';
import LivingInLoveAndRelationships from './LivingInLoveAndRealationships'
import PathwaysToAHappyLife from './PathwaysToAHappyLife';
import EthicsAndMoralityPastToFuture from './EthicsAndMoralityPastToFuture'

// Importing pictures
import grey_left_acordion from '../../images/icons/Grey_left_acordion.svg'
import grey_right_acordion from '../../images/icons/Grey_right_acordion.svg'



const PhilosopherThemeCarousel = ({ setIsQuestionsOpen, setIsPhilosopherCatalogVisible, setCurrentUserQuestion, setSpeakerSequence, setIsPhilosophersResponding, setCurrentSpeakerIndex, conversationHistory }) => {

  const [currentThemeIndex, setCurrentThemeIndex] = useState(0);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isAutoPlayPaused, setAutoPlayOnPause] = useState(false);

  const themes = [
    <LivingInLoveAndRelationships 
      key='1' 
      setIsQuestionsOpen={setIsQuestionsOpen}
      setIsPhilosopherCatalogVisible={setIsPhilosopherCatalogVisible}
      setCurrentUserQuestion={setCurrentUserQuestion} 
      setSpeakerSequence={setSpeakerSequence} 
      setIsPhilosophersResponding={setIsPhilosophersResponding}
      setCurrentSpeakerIndex={setCurrentSpeakerIndex}
      currentThemeIndex = {currentThemeIndex}
      setAutoPlayOnPause = {setAutoPlayOnPause}
    />,
    <FindingMeaningInLife 
      key='2' 
      setIsQuestionsOpen={setIsQuestionsOpen}
      setIsPhilosopherCatalogVisible={setIsPhilosopherCatalogVisible}
      setCurrentUserQuestion={setCurrentUserQuestion} 
      setSpeakerSequence={setSpeakerSequence} 
      setIsPhilosophersResponding={setIsPhilosophersResponding}
      setCurrentSpeakerIndex={setCurrentSpeakerIndex}
      currentThemeIndex = {currentThemeIndex}
      setAutoPlayOnPause = {setAutoPlayOnPause}
    />,
    <PathwaysToAHappyLife 
      key='3' 
      setIsQuestionsOpen={setIsQuestionsOpen}
      setIsPhilosopherCatalogVisible={setIsPhilosopherCatalogVisible} 
      setCurrentUserQuestion={setCurrentUserQuestion} 
      setSpeakerSequence={setSpeakerSequence} 
      setIsPhilosophersResponding={setIsPhilosophersResponding}
      setCurrentSpeakerIndex={setCurrentSpeakerIndex}
      currentThemeIndex = {currentThemeIndex}
      setAutoPlayOnPause = {setAutoPlayOnPause}
    />,
    <NavigatingLifesHardships 
      key='4' 
      setIsQuestionsOpen={setIsQuestionsOpen}
      setIsPhilosopherCatalogVisible={setIsPhilosopherCatalogVisible}
      setCurrentUserQuestion={setCurrentUserQuestion} 
      setSpeakerSequence={setSpeakerSequence} 
      setIsPhilosophersResponding={setIsPhilosophersResponding}
      setCurrentSpeakerIndex={setCurrentSpeakerIndex}
      currentThemeIndex = {currentThemeIndex}
      setAutoPlayOnPause = {setAutoPlayOnPause}
    />,
    <EthicsAndMoralityPastToFuture 
      key='5' 
      setIsQuestionsOpen={setIsQuestionsOpen}
      setIsPhilosopherCatalogVisible={setIsPhilosopherCatalogVisible}
      setCurrentUserQuestion={setCurrentUserQuestion} 
      setSpeakerSequence={setSpeakerSequence} 
      setIsPhilosophersResponding={setIsPhilosophersResponding}
      setCurrentSpeakerIndex={setCurrentSpeakerIndex}
      currentThemeIndex = {currentThemeIndex}
      setAutoPlayOnPause = {setAutoPlayOnPause}
    />,
  ];

  const handleTransition = (newIndex) => {
    setIsFadingOut(true);
    setTimeout(() => {
      setCurrentThemeIndex(newIndex);
      setIsFadingOut(false);
    }, 1000);
  }

  const handleNext = () => {
    const nextIndex = (currentThemeIndex + 1) % themes.length;
    handleTransition(nextIndex);
  };

  const handlePrev = () => {
    const prevIndex = (currentThemeIndex - 1 + themes.length) % themes.length;
    handleTransition(prevIndex);
  };

  const handleDotClick = (index) => {
    handleTransition(index);
  };

  useEffect(() => {
    if (isAutoPlayPaused) return;

    const interval = setInterval(() => {
      handleNext();
    }, 8000);
    return () => clearInterval(interval);
  }, [currentThemeIndex, isAutoPlayPaused]);

  return (
    <div className='Top-carousel'>
      <div className={'Carousel-content'} >
        {themes.map((theme, index) => (
          <div
          key={index}
          className={`Theme ${index === currentThemeIndex ? (isFadingOut ? 'fade-out' : 'fade-in') : 'hidden'}`}
          >
            {theme}
          </div>
        ))}
      </div>
      <div className='Carousel-controls'>
        <button className='Accordion-buttons' id='Left-accordion-button' onClick={handlePrev} > 
          <img src={grey_left_acordion}/>
        </button >
        {themes.map((_, index) => (
          <span
            key={index}
            className={`Dot ${index === currentThemeIndex ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
        <button className='Accordion-buttons' id='Right-accordion-button' onClick={handleNext} > 
          <img src={grey_right_acordion}/>
        </button>
        {/* Pre-loading of the next theme
        <div style={{ display: 'none' }}>
          {themes[(currentThemeIndex + 1) % themes.length]}
        </div> */}
      </div>
    </div>
  );
};

export default PhilosopherThemeCarousel;