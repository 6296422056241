// components/ProTipsSection.js
import React, {} from 'react';
import '../App.css';

const ProTipsSection = ({ isVisible }) => {

  return (
    <div className={`Pro-tips-section-${isVisible ? 'visible' : 'hidden'}`}>
        <h2>Pro Tips</h2>
        <div className='Tips'>
          <div className='Tip-type'>
           <p>Prompting</p>
           <ul>
              <li>Write “Everyone” to make all in the chat answer.</li>
              <li>Don’t address anyone to let the system choose the most relevant responder (usually two).</li>
              <li>Direct your prompt to specific philosopher(s) to make them answer.
                  <ul>
                      <li>Example: <em>“Plato and Lao Tzu, what is the purpose of philosophy?”</em></li>
                  </ul>
              </li>
              <li>Describe a sequence or workflow for a structured discussion.
                  <ul>
                      <li>Example: <em>“Plato and Lao Tzu, share your most valuable idea, then plato review Lao Tzu's idea and Lao Tzu review Plato's idea.”</em></li>
                  </ul>
              </li>
          </ul>
          </div>
          <div className='Tip-type'>
            <p>Find Your Philosopher</p>
            <ul>
              <li>Press “Ctrl-F” (PC) or “Cmd-F” (Mac) to search for a philosopher’s name.</li>
              <li>Ask your favorite philosopher for recommendations on who to talk to next.</li>
          </ul>
          </div>
        </div>
    </div>
  );
};

export default ProTipsSection;
