// Assuming you have two icons imported
import React from 'react';
import spheres from '../images/Divider-middle-spheres.svg';
import '../App.css';

const DividerLine = () => {
  return (
    <div className='Divider-line' id='Divider-line' >
      <div id='End-sphere'></div>
      <div id='Spacer-line'></div>
      <img src={spheres}></img>
      <div id='Spacer-line'></div>
      <div id='End-sphere'></div>
    </div>
  );
};

export default DividerLine;


